import React, { useState } from "react";
import "./PrintHandover.css";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ConfirmationModal from "./ConfirmationModal";
import { updateHandover } from "src/db/serviceHandover";

interface PrintHandoverModalProps {
  onClose: () => void;
  defaultData?: {
    noForm?: string;
    tanggalPickUp?: string; // Expecting ISO date string YYYY-MM-DD
    waktuPickUp?: string; // Expecting time in HH:MM format
    layananPengiriman?: string;
    namaDriver?: string;
    nomorKendaraan?: string;
    paketDiscan?: number;
    paketBerhasilScan?: number;
    paketGagal?: number;
    paketBatal?: number;
    catatan?: string;
  };
}

const PrintHandoverModal: React.FC<PrintHandoverModalProps> = ({
  onClose,
  defaultData = {},
}) => {
  const [formData, setFormData] = useState({
    noForm: defaultData.noForm || "",
    tanggalPickUp: defaultData.tanggalPickUp || "",
    waktuPickUp: defaultData.waktuPickUp || "",
    layananPengiriman: defaultData.layananPengiriman || "SHOPEE EXPRESS",
    namaDriver: defaultData.namaDriver || "",
    nomorKendaraan: defaultData.nomorKendaraan || "",
    paketDiscan: defaultData.paketDiscan || 0,
    paketBerhasilScan: defaultData.paketBerhasilScan || 0,
    paketGagal: defaultData.paketGagal || 0,
    paketBatal: defaultData.paketBatal || 0,
    catatan: defaultData.catatan || "",
  });

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handlePrintOrSave = async (action: "print" | "save") => {
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [50, 100], // Format 80mm x 297mm
    });

    const content = document.getElementById("print-content");
    if (content) {
      const canvas = await html2canvas(content);
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 70; // Width of the image
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Automatic height
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight); // Margin 5mm
      heightLeft -= pdf.internal.pageSize.height;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight); // Margin 5mm
        heightLeft -= pdf.internal.pageSize.height;
      }

      if (action === "print") {
        const blobUrl = pdf.output("bloburl").toString();
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = blobUrl;

        document.body.appendChild(iframe);
        iframe.onload = function () {
          if (iframe.contentWindow) {
            iframe.contentWindow.print();
          }
          setTimeout(() => {
            document.body.removeChild(iframe);
          }, 5000000);
        };
      } else if (action === "save") {
        pdf.save("handover.pdf");
      }
    }

    onClose();
  };

  const handlePrint = () => {
    setShowConfirmation(true); // Show the confirmation modal
  };

  const handleConfirmPrint = async () => {
    handlePrintOrSave("print");
    setShowConfirmation(false); // Close confirmation modal
    try {
      await updateHandover(formData.noForm);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmSave = async () => {
    handlePrintOrSave("save");
    setShowConfirmation(false); // Close confirmation modal
    try {
      await updateHandover(formData.noForm);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setFormData({
      noForm: "",
      tanggalPickUp: "",
      waktuPickUp: "",
      layananPengiriman: "SHOPEE EXPRESS",
      namaDriver: "",
      nomorKendaraan: "",
      paketDiscan: 0,
      paketBerhasilScan: 0,
      paketGagal: 0,
      paketBatal: 0,
      catatan: "",
    });
    onClose();
  };

  const { t } = useTranslation();

  return (
    <div className="modal-overlay" data-testid="print-handover-modal">
      <div className="modal-content">
        <div id="print-content">
          <h2>{t("Shipping.Handover.Aside")}</h2>
          <ul className="form-list">
            <li>
              <strong>{t("Handover.Modal.No")}:</strong> {formData.noForm}
            </li>
            <li>
              <strong>{t("Shipping.Handover.Date")}:</strong>{" "}
              {formData.tanggalPickUp}
            </li>
            <li>
              <strong>{t("Shipping.Handover.Time")}:</strong>{" "}
              {formData.waktuPickUp}
            </li>
            <li>
              <strong>{t("Shipping.Handover.Shipper")}:</strong>{" "}
              {formData.layananPengiriman}
            </li>
            <li>
              <strong>{t("Shipping.Handover.Driver")}:</strong>{" "}
              {formData.namaDriver}
            </li>
            <li>
              <strong>{t("Shipping.Handover.Vehicle")}:</strong>{" "}
              {formData.nomorKendaraan}
            </li>
            <li>
              <strong>{t("Handover.Modal.Scanned")}:</strong>{" "}
              {formData.paketDiscan}
            </li>
            <li>
              <strong>{t("Handover.Modal.Success")}:</strong>{" "}
              {formData.paketBerhasilScan}
            </li>
            <li>
              <strong>{t("Handover.Modal.Failed")}:</strong>{" "}
              {formData.paketGagal}
            </li>
            <li>
              <strong>{t("Handover.Modal.Cancel")}:</strong>{" "}
              {formData.paketBatal}
            </li>
            <li>
              <strong>{t("Handover.Modal.Note")}:</strong> {formData.catatan}
            </li>
          </ul>
          <div className="signature-container">
            <div className="signature-a">
              <label className="driverName">
                {t("Handover.Driver.Signature")}
              </label>
              <label className="driverSignature">{formData.namaDriver}</label>
            </div>
            <div className="signature-b">
              <label className="userName">{t("Handover.User.Signature")}</label>
              <label className="userSignature"></label>
            </div>
          </div>
        </div>

        <div className="button-row">
          <button className="btn btn-light-primary" onClick={handleClose}>
            {t("Common.Button.Cancel")}
          </button>
          <button className="btn btn-primary" onClick={handlePrint}>
            {t("Shipping.Handover.Print")}
          </button>
        </div>
      </div>

      {/* Render the confirmation modal if needed */}
      {showConfirmation && (
        <ConfirmationModal
          message="Do you want to print or save the document? Press OK for Print, Cancel for Save."
          onConfirm={handleConfirmPrint}
          onCancel={handleConfirmSave}
        />
      )}
    </div>
  );
};

export default PrintHandoverModal;
