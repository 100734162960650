import axios from "axios";
import * as bcrypt from "bcryptjs";
import * as ApiSettingService from "../../db/serviceApiSetting";
import { toast } from "react-toastify";
import { Timestamp } from "firebase-admin/firestore";

import firebase from "firebase/compat/app";
import { createRef } from "src/db";
import * as lc from "../../app/modules/localstorage/index";

export interface IExportOrderResponse {
  error?: string;
  message?: string;
  warning?: string;
  response?: string;
}

//TODO make a connection to api server  for export order
export const sendExportOrder = async (
  jsonRequestData: Object,
  callback: (err: number, response: IExportOrderResponse) => void
) => {
  const url: string =
    process.env.REACT_APP_SERVER_URL! + "/7days/order/management/export";
  const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

  console.log("URL sendExportOrder: " + url);

  try {
    await axios
      .post(url, jsonRequestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const responseJson = response.data;
        const responseCode = response.status;
        return callback(responseCode, responseJson);
      })
      .catch((error) => {
        //return error callback
        return callback(error.response.status, error.response.data);
      });
  } catch (err) {
    console.log("Error Request server : " + err);
    return callback(503, {
      message: `Service Unavailable`,
    });
  }
};

const dummyData = {
  message: "Success",
  processId: "123123123123",
  data: [
    {
      accountName: "LazadaSZ",
      count: 2,
    },
    {
      accountName: "ShopeeFS",
      count: 4,
    },
  ],
};

export const reqReadyToShipOrder = async (
  orders: string[],
  userID?: string,
  processID?: string
) => {
  const url: string = process.env.REACT_APP_SERVER_URL! + "/7days/orders/rts";
  try {
    const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);
    const reqBody: {
      orders: string[];
      userID?: string;
      processID?: string;
    } = {
      orders: orders,
    };

    if (userID) {
      reqBody.userID = userID;
    }
    if (processID) {
      reqBody.processID = processID;
    }
    console.log("SET_READY_TO_SHIP", { orders });
    const response = await axios.post(url, reqBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err: any) {
    console.error("Error Request server:", err);
    if (axios.isAxiosError(err)) {
      // Handle axios-specific errors
      if (err.response) {
        // Client received an error response (5xx, 4xx)
        toast(
          `Error: ${err.response.status}, Message: ${err.message}, Please try again later`,
          {
            type: "error",
            position: "top-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } else if (err.request) {
        // Client never received a response, or request never left
        toast(`Network error, can't send a request, Please try again later`, {
          type: "error",
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Anything else
        toast(`Error: ${err.message}, Please try again later`, {
          type: "error",
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } else {
      // Handle non-axios-specific errors
      toast(`Unexpected Error: ${err.message}, Please try again later`, {
        type: "error",
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    return undefined;
  }
};

export const reqDownloadCSVExportedOrders = async (
  orders: string[],
  userID: string,
  processID?: string
) => {
  const url: string =
    process.env.REACT_APP_SERVER_URL! + "/7days/orders/download";
  const reqBody: any = {
    orders: orders,
    userID: userID,
  };

  if (processID) {
    reqBody["processID"] = processID;
  }

  try {
    // get latest access token from database
    const accountAccess = await ApiSettingService.getAccess();
    if (!accountAccess) {
      console.error("Failed To Get Access Token");
      return undefined;
    }
    const token = accountAccess?.accessToken;

    const response = await axios.post(url, reqBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Response From Download Exported Order", response.data);
    return response.data;
  } catch (err) {
    console.error("Error Request server : " + err);
    return undefined;
  }
};

export type syncOrderProps = {
  startTime?: string;
  endTime?: string;
  accounts?: string[];
};

export const syncOrder = async (
  syncOrderProps: syncOrderProps,
  client: string
) => {
  if (!syncOrderProps) {
    console.log("Invalid syncOrderProps");
    return undefined;
  }
  const url: string = process.env.REACT_APP_SERVER_URL! + "/orders/sync-orders";
  console.log("Start Time:", syncOrderProps.startTime);
  console.log("End Time:", syncOrderProps.endTime);
  console.log("Account IDs:", syncOrderProps.accounts);
  console.log("Client ID:", client);
  console.log("URL: " + url);
  try {
    const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

    const requestBody: {
      client: string;
      accounts?: string[];
      startTime?: string;
      endTime?: string;
    } = {
      client: client,
      accounts: syncOrderProps.accounts,
      startTime: syncOrderProps.startTime,
      endTime: syncOrderProps.endTime,
    };

    const response = await axios.post(url, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Response Status:", response.status, "ulti");
    console.log("Response Data:", response.data);
    return response.data;
  } catch (error: any) {
    console.log("Error post to server:", error, "ulti");

    if (error.response) {
      console.log("Error response status:", error.response.status);
      console.log("Error response data:", error.response.data);
    } else if (error.request) {
      console.log("No response received from the server");
    } else {
      console.log("Error message:", error.message);
    }

    return undefined;
  }
};

export const sendRequestInitiateChatMarketplace = async (
  marketplaceType: string,
  orderId: string,
  userId: string
) => {
  console.log("==> Masuk sendRequestInitiateChatMarketplace INCHA");
  console.log(marketplaceType, "Marketplacetype INCHA");
  console.log(orderId, "orderId INCHA");
  console.log(userId, "userId INCHA");
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    `/${marketplaceType}/chat/initiate-chat`;
  console.log(url, "url INCHA");
  const requestBody = {
    orderId: orderId,
    userId: userId,
  };
  console.log(requestBody, "requestBody INCHA");
  // get latest access token from database
  const accountAccess = await ApiSettingService.getAccess();
  if (!accountAccess) {
    console.error("Failed To Get Access Token");
    return undefined;
  }
  const token = accountAccess?.accessToken;
  console.log(token, "token INCHA");
  const response = await axios.post(url, requestBody, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(response, "response INCHA");

  return response.data;
};

export const sendRequestShippingMark = async (
  packingStations?: Array<string>,
  qtyCount?: number,
  orderSN?: any,
  shipper?: string,
  handoverId?: string
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/orders/shippingMark`;
  const token = process.env.REACT_APP_CLIENT_TOKEN;

  if (!packingStations && qtyCount === undefined && !orderSN) {
    throw new Error(
      "At least one of packingStation, qtyCount, or orderSN is required."
    );
  }
  console.log(handoverId, " ini handover id");
  const requestBody = {
    packingStations,
    qtyCount,
    orderSN,
    shipper,
    handoverId,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      console.error(
        "Error sending request for shipping mark:",
        error.response.data
      );
      throw new Error(`${error.response.data.message}`);
    } else if (error instanceof Error) {
      console.error("Error sending request for shipping mark:", error);
      throw new Error(`Failed to send shipping mark: ${error.message}`);
    } else {
      console.error("Unexpected error:", error);
      throw new Error(
        "Failed to send shipping mark request: An unexpected error occurred."
      );
    }
  }
};

export const sendRequestShippingHandover = async (
  driverName?: string,
  vehicleNumber?: string,
  packageScannedQty?: number,
  succesScannedQty?: number,
  failedScannedQty?: number,
  canceledPackage?: number,
  date?: string,
  time?: string,
  shipper?: string,
  description?: string,
  handoverId?: string
) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/orders/shipmentHandOver`;
  const token = process.env.REACT_APP_CLIENT_TOKEN;

  if (!shipper) {
    throw new Error("Shipper is required.");
  }

  console.log(handoverId, "ini handover id");
  // Retrieve client information from local storage
  const client = lc.getItemLC(lc.LCName.Client);

  if (!client || !client.id) {
    throw new Error("Client information is missing or invalid.");
  }

  const requestBody = {
    driverName,
    vehicleNumber,
    packageScannedQty,
    succesScannedQty,
    failedScannedQty,
    canceledPackage,
    date,
    time,
    shipper,
    description,
    client,
    handoverId,
  };

  console.log("REQUEST TO SERVER SHIPPING HANDOVER", requestBody);

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("Response from shipping handover:", response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Handle axios-specific errors
      const errorMessage = error.response
        ? `Error: ${error.response.status}, ${
            error.response.data.message || error.message
          }`
        : `Network error: ${error.message}`;

      console.error(
        "Error sending request for shipping handover:",
        errorMessage
      );
      throw new Error(
        `Failed to send shipping handover request: ${errorMessage}`
      );
    } else {
      // Handle non-axios-specific errors
      console.error("Unexpected error:", error);
      throw new Error(
        "Failed to send shipping handover request: An unexpected error occurred."
      );
    }
  }
};
