import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import * as chatAct from "../../actions/chat";
import * as api from "../../api";
import {
  subsToFriendListMessages,
  subsToHanldeCollabsByAnotherUser,
  subsToNewMessageCollaboration,
  subsToNotificationMessageCollabByAnotherUser,
  subsToUnhandledCollabs,
  subsToNotificationSyncOrderMarketplace,
} from "../../api/firebase";
import ContacsListPage from "../../app/layout/contact-management/ContactPage";
import { createRef } from "../../db/connection";
import { MasterLayout } from "../layout/MasterLayout";
import CampaignPage from "../layout/campaign/CampaignPage";
import CampaignReportPage from "../layout/campaign/CampaignReportPage";
import CampaignEditor from "../layout/campaign/campaign-create/CampaignEditor";
import CIQListPage from "../layout/ciq-management/CIQPage";
import OrderExport from "../layout/order/order-export/OrderExport";
import OrderExportDownload from "../layout/order/order-export/OrderExportDownload";
import OrderExportInfo from "../layout/order/order-export/OrderExportInfo";
import ProcessPage from "../layout/order/process-order/ProcessPage";
import OrderPage from "../layout/order/shiporder-export/OrderPage";
import StorefrontListPage from "../layout/product-management/StorefrontListPage";
import ProductListPage from "../layout/product-management/product/ProductListPage";

import ProductListView from "../layout/product-management/productList-Remake/ProductListView";

import BasicProductInformation from "../layout/product-management/product/product-create/components/BasicProductInformation";
import SelectCompanyAndMarketplace from "../layout/product-management/product/product-create/components/CompanyAndMarketplace";
import { default as MediaUpload } from "../layout/product-management/product/product-create/components/MediaUpload";
import ProductCategoryAttributes from "../layout/product-management/product/product-create/components/ProductCategoryAttributes";
import ProductSummary from "../layout/product-management/product/product-create/components/ProductSummary";
import ProductVariantsCategory from "../layout/product-management/product/product-create/components/ProductVariantsCategory";
import ProductVariantsList from "../layout/product-management/product/product-create/components/ProductVariantsList";
import ProductWeight from "../layout/product-management/product/product-create/components/ProductWeight";
import Shipments from "../layout/product-management/product/product-create/components/Shipments";
import StorefrontName from "../layout/product-management/storefront/storefront-create/components/StorefrontName";
import StorefrontSelectMarketplace from "../layout/product-management/storefront/storefront-create/components/StorefrontSelectMarketplace";
import StorefrontSummary from "../layout/product-management/storefront/storefront-create/components/StorefrontSummary";
import TemplatePage from "../layout/template/TemplatePage";
import TemplateUpdate from "../layout/template/template-create/TemplateConfiguration";
import WABAAccountsListPage from "../layout/user-menu/settings/WABAAccount/WABAAccountsPage";
import WABASignUp from "../layout/user-menu/settings/WABAAccount/account-create/components/WABASignUp";
import WABASummary from "../layout/user-menu/settings/WABAAccount/account-create/components/WABASummary";
import WABAVerify from "../layout/user-menu/settings/WABAAccount/account-create/components/WABAVerify";
import MarketplaceListPage from "../layout/user-menu/settings/Marketplace/MarketplacePage";
import MobileNavigation from "../layout/user-menu/settings/nav/MobileNavigation";
import Message from "../models/Message";
import Notification from "../models/Notification";
import User, { ClientData } from "../models/User";
import { setAuth } from "../modules/auth/redux/AuthSlice";
import * as chat from "../modules/chat/redux/ChatSlice";
import * as CIQ from "../modules/ciq/redux/CIQSlice";
import { LCName, getItemLC, setItemLC } from "../modules/localstorage";
import * as lc from "../modules/localstorage/index";
import { infoNewMessage } from "../modules/notify";
import { infoSyncOrder } from "../modules/notify";
import { removeFromNotificationsByCollabID } from "../modules/notify/Notification/helper";
import { About } from "../pages/About";
import { ContactDetail } from "../pages/ContactDetail";
import { Faq } from "../pages/FAQ";
import { HandledCustomer } from "../pages/HandledCustomer";
import HomePage from "../pages/Home";
import { UserChat } from "../pages/UserChat";
import { Order } from "../models/Order";
import store, { RootState } from "../../setup/redux/store";
import { APISetting } from "../layout/user-menu/settings/api-setting";
import MemberListPage from "../layout/user-menu/settings/user-management/MemberPage";
import AddMember from "../layout/user-menu/settings/user-management/add-member/AddMember";
import * as RoomService from "../../db/serviceRoom";
import { Collaboration } from "../models/Chat";
import MarketplaceEditPage from "../layout/user-menu/settings/Marketplace/MarketplaceEditPage";
//import ListComponent from "../layout/new-list/NewList";
import NewListPage from "../components/list/_example_/New_Page_Example";
import NewOrderPage from "../layout/order/new-orderlist/NewOrderPage";
import TemplateUpdateCreate from "../layout/template/new-template-create/configuration";
import NewContactPage from "../layout/contact-management/new-contact-list/NewContactPage";
import CIQNewList from "../layout/ciq-management/ciq-newlist/CIQNewList";
import { HandleChat } from "../pages/HandleChat";
import ShippinghandoverPage from "../layout/order/shipping-handover/ShippinghandoverPage";

export function isUserDataCompleted(userData?: User) {
  if (
    userData &&
    userData.id &&
    userData.id !== "" &&
    (userData.avatar || userData.avatar === "") &&
    (userData.name || userData.name === "")
  ) {
    return true;
  } else {
    return false;
  }
}

export const isClientDataCompleted = (client?: ClientData): boolean => {
  if (
    client &&
    client.id &&
    (client.avatar || client.avatar === "") &&
    (client.clientName || client.clientName === "")
  ) {
    return true;
  } else {
    return false;
  }
};

const PrivateRoutes = () => {
  const userId = getItemLC("UID");
  const companyId = getItemLC("CID");
  const dispatch = useDispatch();
  const client = getItemLC("Client");
  const userData = getItemLC("UserData");
  const selectedChat = useSelector(
    (state: RootState) => state.Chat.selectedChat
  );
  const selectedChatRef = useRef(selectedChat);
  const selectedRoom = useSelector(
    (state: RootState) => state.Chat.selectedRoom
  );
  const selectedRoomRef = useRef(selectedRoom);
  const allChatSelectedChat = useSelector(
    (state: RootState) => state.Chat.allChatSelectedChat
  );
  const allChatSelectedChatRef = useRef(allChatSelectedChat);
  const allChatSelectedRoom = useSelector(
    (state: RootState) => state.Chat.allChatSelectedRoom
  );
  const allChatSelectedRoomRef = useRef(allChatSelectedRoom);

  if (
    !userId ||
    !isUserDataCompleted(userData as User) ||
    !isClientDataCompleted(client as ClientData)
  ) {
    console.log(`set auth false`);
    api.logout().then(() => {
      dispatch(setAuth(false));
      lc.removeSession();
    });
  }

  const requestAudioPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return "granted";
    } catch (error) {
      console.log("Error requesting audio permission:", error);
      return "denied";
    }
  };

  let listPlayedNotif: any[] = [];

  const playAudio = async (collaborationID: string) => {
    if (listPlayedNotif.length === 0) {
      listPlayedNotif.push(collaborationID);
      const permissionState = await requestAudioPermission();
      if (permissionState === "granted") {
        var audio = new Audio("/media/misc/audio/notification.mp3");
        await audio.play();
      } else {
        console.log("Permission denied for audio playback.");
      }
    } else {
      setTimeout(() => {
        listPlayedNotif = [];
      }, 10000);
    }
  };

  const handleNewMessageNotification = async (
    Notif: Notification,
    message: Message | undefined | Order | any
  ) => {
    if (message && Notif?.collaborationID) {
      const inQueuedChat = await RoomService.getQueuedRooms();
      const inQueuedChatUpdate = inQueuedChat.find(
        (room) => room.collaborationId === Notif?.collaborationID
      );
      if (inQueuedChatUpdate) {
        dispatch(CIQ.updateListCIQ(inQueuedChatUpdate));
      }

      await chatAct
        .fetchCollaborationByID(Notif?.collaborationID)
        .then(async (collab: any) => {
          if (collab) {
            const refUser = createRef("users", getItemLC("UID"));
            let userCollab = undefined;
            if (collab?.toUser) {
              userCollab = collab.toUser?.find(
                (obj: any) => obj.id === refUser.id
              );
            }
            const currentSelectedChat = selectedChatRef.current;
            const currentSelectedRoom = selectedRoomRef.current;
            const currentallChatSelectedChat = allChatSelectedChatRef.current;
            const currentallChatSelectedRoom = allChatSelectedRoomRef.current;
            if (userCollab !== undefined) {
              dispatch(chat.updateChatList(collab));
              dispatch(chat.addIncomingMessages(message));
              if (
                currentSelectedChat === collab.id &&
                currentSelectedRoom === message?.room?.id
              ) {
                dispatch(chat.setSelectedChat(collab?.id));
                dispatch(chat.setSelectedRoom(message?.room?.id));
              } else {
                dispatch(chat.setSelectedChat(currentSelectedChat));
                dispatch(chat.setSelectedRoom(currentSelectedRoom));
              }

              const HALF_A_MINUTE = 30 * 1000;
              if (
                new Date().getTime() -
                  new Date(message.updatedAt.seconds * 1000).getTime() <
                HALF_A_MINUTE
              ) {
                infoNewMessage(message.textContent, true, Notif);
              }
            } else {
              collab.toUserData = collab?.roomsModel[0]?.toUserData || [];
              collab.lastCustomerInteractionAt =
                collab?.roomsModel[0]?.lastCustomerInteractionAt || null;
              dispatch(chat.updateAllChatChatList(collab));
              dispatch(CIQ.deleteItemListCIQ(collab.id));
              removeFromNotificationsByCollabID(collab.id);

              if (currentallChatSelectedChat === collab.id) {
                dispatch(chat.setAllChatSelectedChat(""));
                dispatch(chat.setAllChatSelectedRoom(""));
                dispatch(chat.setAllChatSelectedChat(collab?.id));
                dispatch(chat.setAllChatSelectedRoom(message?.room?.id));
              } else {
                dispatch(
                  chat.setAllChatSelectedChat(currentallChatSelectedChat)
                );
                dispatch(
                  chat.setAllChatSelectedRoom(currentallChatSelectedRoom)
                );
              }

              const HALF_A_MINUTE = 30 * 1000;
              if (
                new Date().getTime() -
                  new Date(message.updatedAt.seconds * 1000).getTime() <
                HALF_A_MINUTE
              ) {
                infoNewMessage(message.textContent, true, Notif);
              }
            }
          }
        });
    }
  };

  const handleCIQMessageNotification = async (Notif: Notification) => {
    if (Notif.collaborationID) {
      const inQueuedChat = await RoomService.getQueuedRooms();
      const inQueuedChatUpdate = inQueuedChat.find(
        (room) => room.collaborationId === Notif?.collaborationID
      );
      if (inQueuedChatUpdate) {
        dispatch(CIQ.updateListCIQ(inQueuedChatUpdate));
      }
      infoNewMessage("", true, Notif);
    }
  };

  const handleNewUserMessageNotification = async (
    Notif: Notification,
    message: Message | undefined | Order | any
  ) => {
    if (message && Notif.collaborationID) {
      dispatch(chat.addUserIncomingMessages(message));
      await chatAct
        .fetchUserCollaborationByID(Notif.collaborationID)
        .then((collab) => {
          if (collab) {
            dispatch(chat.updateUserChatList(collab));
          }
        });
      infoNewMessage("", true, Notif);
    }
  };

  const handleCIQHandledNotification = async (Notif: Notification) => {
    if (Notif && Notif.collaborationID) {
      await chatAct
        .fetchCollaborationByID(Notif.collaborationID)
        .then((collab) => {
          if (collab) {
            dispatch(chat.updateAllChatChatList(collab));
            dispatch(CIQ.deleteItemListCIQ(collab.id));
            removeFromNotificationsByCollabID(collab.id);
          }
        });
    }
  };

  const handleMultiUserMessageNotification = async (
    Notif: Notification,
    message: Message | undefined | Order | any
  ) => {
    if (message && Notif.collaborationID) {
      await chatAct
        .fetchCollaborationByID(Notif.collaborationID)
        .then((collab) => {
          if (collab) {
            const refUser = createRef("users", getItemLC("UID"));
            let userCollab = undefined;
            if (collab.toUser) {
              userCollab = collab.toUser?.find((obj) => obj.id === refUser.id);
            }
            if (userCollab) {
              dispatch(chat.addIncomingMessages(message));
              dispatch(chat.updateChatList(collab));
            } else {
              dispatch(chat.addAllChatIncomingMessages(message));
              dispatch(chat.updateAllChatChatList(collab));
            }
          }
        });
    }
  };

  const handleSyncOrderNotification = async (
    Notif: Notification,
    data: Message | undefined | Order | any
  ) => {
    const order = data;
    infoSyncOrder(order, true, Notif);
  };

  const onNewData = async (
    Notif: Notification,
    data: Message | undefined | Order | any
  ) => {
    try {
      switch (Notif.notifType) {
        case "newMessage":
          await handleNewMessageNotification(Notif, data);
          break;
        case "CIQMessage":
          await handleCIQMessageNotification(Notif);
          break;
        case "newUserMessage":
          await handleNewUserMessageNotification(Notif, data);
          break;
        case "CIQHandled":
          await handleCIQHandledNotification(Notif);
          break;
        case "MultiUserMessage":
          await handleMultiUserMessageNotification(Notif, data);
          break;
        case "SyncOrder":
          await handleSyncOrderNotification(Notif, data);
          break;
        default:
          console.warn(`Unhandled notification type: ${Notif.notifType}`);
          break;
      }

      // Memutar audio setelah menangani notifikasi
      await playAudio(Notif?.collaborationID as string);
    } catch (error) {
      console.error("Error handling new data:", error);
    }
  };

  useEffect(() => {
    if (listPlayedNotif.length > 0) {
      setTimeout(() => {
        listPlayedNotif = [];
      }, 10000);
    }
  }, [listPlayedNotif.length]);

  useEffect(() => {
    const ciqSubs = subsToUnhandledCollabs(userId, companyId, onNewData);
    const ciqHandleByAnotherUser = subsToHanldeCollabsByAnotherUser(
      userId,
      onNewData
    );
    const subsMsgCollabAnotherUser =
      subsToNotificationMessageCollabByAnotherUser(userId, onNewData);
    const unsubsNewMessage = subsToNewMessageCollaboration(userId, onNewData);
    const subsToNotificationSyncOrder = subsToNotificationSyncOrderMarketplace(
      userId,
      onNewData
    );

    return () => {
      ciqSubs();
      ciqHandleByAnotherUser();
      subsMsgCollabAnotherUser();
      unsubsNewMessage();
      subsToNotificationSyncOrder();
    };
  }, []);

  useEffect(() => {
    selectedChatRef.current = selectedChat;
    selectedRoomRef.current = selectedRoom;
    allChatSelectedChatRef.current = allChatSelectedChat;
    allChatSelectedRoomRef.current = allChatSelectedRoom;
  }, [selectedChat, selectedRoom, allChatSelectedChat, allChatSelectedRoom]);

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="dashboard" element={<Navigate to="/home" />} />
        <Route path="about" element={<About />} />
        <Route path="faq" element={<Faq />} />
        <Route path="contact/*" element={<NewContactPage />} />
        <Route path="ciq/*" element={<CIQNewList />} />
        <Route path="list-component/*" element={<NewListPage />} />
        <Route path="contact/contact-detail/*" element={<ContactDetail />} />
        <Route path="handled-customer" element={<HandleChat />} />
        {/* <Route path="handled-customer" element={<HandledCustomer />} /> */}
        {/* <Route path="new-handled-customer" element={<HandleChat />} /> */}
        <Route path="user-conversation" element={<UserChat />} />
        <Route path="campaign/create" element={<CampaignEditor />} />
        <Route
          path="campaign/:campaignId/edit/*"
          element={<CampaignEditor />}
        />
        <Route
          path="campaign/:campaignId/report/*"
          element={<CampaignReportPage />}
        />
        <Route path="campaign/report/*" element={<CampaignReportPage />} />
        <Route path="campaign/*" element={<CampaignPage />} />
        <Route path="template/*" element={<TemplatePage />} />
        <Route path="template/create" element={<TemplateUpdateCreate />} />
        <Route path="template/update/*" element={<TemplateUpdateCreate />} />
        <Route path="storefront/*" element={<StorefrontListPage />} />
        <Route
          path="storefront/create-storefront/storefront-name"
          element={<StorefrontName />}
        />
        <Route
          path="storefront/create-storefront/select-marketplace"
          element={<StorefrontSelectMarketplace />}
        />
        <Route
          path="storefront/create-storefront/summary"
          element={<StorefrontSummary />}
        />
        <Route
          path="storefront/update-storefront/:id"
          element={<StorefrontSummary />}
        />
        <Route path="product/*" element={<ProductListPage />} />
        <Route
          path="product/create-product/media-upload"
          element={<MediaUpload />}
        />
        <Route
          path="product/create-product/package-weight"
          element={<ProductWeight />}
        />
        <Route
          path="product/create-product/shipments"
          element={<Shipments />}
        />
        <Route
          path="product/create-product/select-company-and-marketplace"
          element={<SelectCompanyAndMarketplace />}
        />
        <Route
          path="product/create-product/category-attributes"
          element={<ProductCategoryAttributes />}
        />
        <Route
          path="product/create-product/variants-category"
          element={<ProductVariantsCategory />}
        />
        <Route
          path="product/create-product/variants-list"
          element={<ProductVariantsList />}
        />
        <Route
          path="product/create-product/summary"
          element={<ProductSummary />}
        />
        <Route
          path="product/create-product/basic-information"
          element={<BasicProductInformation isSummary={false} />}
        />
        <Route
          path="product/update/package-weight"
          element={<ProductWeight />}
        />
        <Route path="product/update/shipments" element={<Shipments />} />
        <Route
          path="product/update/select-company-and-marketplace"
          element={<SelectCompanyAndMarketplace />}
        />
        <Route
          path="product/update/category-attributes"
          element={<ProductCategoryAttributes />}
        />
        <Route
          path="product/update/variants-category"
          element={<ProductVariantsCategory />}
        />
        <Route
          path="product/update/variants-list"
          element={<ProductVariantsList />}
        />
        <Route path="product/update/media-upload" element={<MediaUpload />} />
        <Route path="product/update/summary" element={<ProductSummary />} />
        <Route
          path="product/update/basic-information"
          element={<BasicProductInformation isSummary={false} />}
        />
        <Route path="product/update/:id" element={<ProductSummary />} />
        <Route path="/setting/" element={<MobileNavigation />} />
        <Route
          path="/setting/waba-account/*"
          element={<WABAAccountsListPage />}
        />
        <Route
          path="/setting/waba-account/create/sign-up"
          element={<WABASignUp />}
        />
        <Route
          path="/setting/waba-account/create/verify"
          element={<WABAVerify />}
        />
        <Route
          path="/setting/waba-account/create/summary"
          element={<WABASummary />}
        />
        <Route
          path="/setting/marketplace/edit/:id"
          element={<MarketplaceEditPage />}
        />
        <Route
          path="/setting/marketplace/*"
          element={<MarketplaceListPage />}
        />
        <Route path="/setting/api" element={<APISetting />} />
        <Route path="/setting/user-management" element={<MemberListPage />} />
        <Route
          path="/setting/user-management/add-member"
          element={<AddMember />}
        />
        <Route path="order/*" element={<NewOrderPage />} />
        <Route path="order/process" element={<ProcessPage />} />
        <Route path="order/export" element={<OrderExport />} />

        <Route
          path="order/shippingHandover"
          element={<ShippinghandoverPage />}
        />
        <Route path="order/export/download" element={<OrderExportDownload />} />
        <Route path="order/export/information" element={<OrderExportInfo />} />
        <Route path="home" element={<HomePage />} />
        <Route path="*" element={<Navigate to="/error" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
